/* 전체 css 파일입니다. 일부 style에 css가 적용되어있습니다.*/
/* 사용방법은 아래와 같습니다. */
/* styles를 import한 뒤 스타일을 적용 할 컴포넌트에 className을 적고 styles.사용할 css 를 입력하세요. */
/* import styles from 'components/facilityGroup/style.module.css' */
/* EX) <Button className={styles.blueButton}/> */


/* facilityLineList.tsx */
.tableEx {
    text-align: center;
    border-left: 1px solid #E6E7E8;
}

/* 등록 및 정보수정 테이블 */
.regTableTop {
    border-top: 2px solid #BFBFBF;
    padding: 8px;
    border-bottom: 1px dotted #bdbdbd;
}

.regTableBody {
    padding: 8px;
    border-bottom: 1px dotted #bdbdbd;
    display: flex;
}
.regTableBodyPadding1 {
    padding: 8px;
    border-bottom: 1px dotted #bdbdbd;
    display: flex;
}
.regTableBottom {
    padding: 8px;
    border-bottom: 2px solid #BFBFBF;
}

/* 기본테이블 */
.tableHead {
    font-weight: 600;
    text-align: center;
    border-left: 1px solid #E6E7E8;
    border-bottom: 2px solid #1C75BC;
    border-top: 2px solid #A6A6A6;

}

.tableHeadRightEnd {
    font-weight: 600;
    text-align: center;
    border-right: 1px solid #E6E7E8;
    border-left: 1px solid #E6E7E8;
    border-bottom: 2px solid #1C75BC;
    border-top: 2px solid #A6A6A6;

}

.tableHeadEnd {
    font-weight: 600;
    text-align: center;
    border-bottom: 2px solid #1C75BC;
    border-top: 2px solid #A6A6A6;
}

.tableBody {
    text-align: center;
    border-left: 1px solid #E6E7E8;
}

.tableBodyEnd {
    text-align: center;
}

.tableBodyRightEnd {
    text-align: center;
    border-right: 1px solid #E6E7E8;
    border-left: 1px solid #E6E7E8;
}

.tableNoLine {
    text-align: center;
    border-bottom-color: #E6E7E8;
    height: 40px;
}

/* 주황테이블(스케줄 테이블) */
.scheduleTableHead {
    font-weight: 600;
    text-align: center;
    border-left: 1px solid #F8AD52;
    border-bottom: 2px solid #8C4F06;
    border-top: 2px solid #8C4F06;
}

.scheduleTableHeadEnd {
    font-weight: 600;
    text-align: center;
    border-bottom: 2px solid #8C4F06;
    border-top: 2px solid #8C4F06;
}

.scheduleTableBody {
    text-align: center;
    border-left: 1px solid #F8AD52;
    border-bottom-color: #F8AD52;
}

.scheduleTableBodyEnd {
    text-align: center;
    border-bottom-color: #F8AD52;
}

.scheduleTableNoFacility {
    text-align: center;
    border-bottom-color: #F8AD52;
    height: 40px;
}

/* 버튼  */
.primaryButtonLogin {
    margin: 5px;
    min-width: 64px;
    padding: 4px 5px;
}

.primaryButton {
    margin: 5px;
    min-width: 64px;
    padding: 4px 5px;
    background-color: #0C3149;
    color: #FFFFFF;
}

.primaryButton:Hover {
    background-color: #071B29;
}

.blueButton {
    color: #FFFFFF;
    margin: 5px;
    background: #1C75BC;
    min-width: 62px;
    padding: 4px 5px;
}

.blueButton:hover {
    background-color: #10548A;
}

.greenButton {
    color: #FFFFFF;
    margin: 5px;
    background: #125B37;
    min-width: 64px;
    padding: 4px 5px;
}

.greenButton:hover {
    background-color: #0E3B25;
}

.monthButton {
    width: 20px;
    height: 20px;
    background-color: #219ebc;
    color: #FFFFFF;
    border-radius: 40px;
    margin-left: 1%;
    margin-top: 1%
}

.monthButton:hover {
    background-color: rgb(23, 106, 173);
}

.errorCommentAddButton {
    top: 13px;
    color: #5B9BD5;
    border-color: #5B9BD5;
}

.errorCommentAddButton:hover {
    border-color: #1C75BC,
}

/* 하단 카운트 */
.bottomCount {
    position: relative;
    width: 200px;
    left: 0.5%;
    color: #6B778C;
    font-weight: 400px;
    font-size: 16px;
    line-height: 200%;
}

.pagination {
    height: 50px;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    /* color:#0C3149; */
}

/* 툴바 */
.toolbarTitle {
    font-size: 30px;
    font-weight: 600px;
    color: #0C3149;
    display: flex;
    position: relative;
    left: 1%;
    margin-bottom: 1%;
}

/* 적용안됨...  수정필요*/
.toolbarBorderBox {
    background-color: #FFFFFF;
    margin: 1px;
    border: 1px;
    width: 100%;
    height: 6rem;
    border-radius: 3px;
    border-color: #1C75BC;
    margin-bottom: 2%;
    margin: auto;
}

.grdationEx {
    background: linear-gradient(to right, #D3EAF5, #7FB0EA);
    border: 1px solid #7FB0EA;
    border-radius: 10px;
}

.dashBoardLineNameBoxJustify {
    background-color: #415A77;
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.dashBoardLineNameBoxBlock {
    background-color: #415A77;
    display: block;
    padding: 12px;
}

.pageTitle {
    font-size: 30px;
    font-weight: 600;
    color: #0C3149;
    display: flex;
    position: relative;
    left: 1rem;
    margin-bottom: 1%;
}
.pageTitleNoBorder{
    font-size: 30px;
    font-weight: 600;
    color: '#0C3149';
    display: flex;
    position: relative;
    left: 2.2%;
    margin-bottom: 1%;
}
.pageDirection{
    position: relative;
    height: 100%;
    text-align: right;
    right: 1%;
}